var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(81, "Configuration"), "visible-initial": "" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(71, "Production name"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.carbonClapData.ccProjectName,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "3" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(72, "Type") } },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.getLabelMenuProjectType(
                                  _vm.carbonClapData.productionType
                                ),
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "4" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(720, "Production") } },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.carbonClapData.production,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(
                                75,
                                "Total duration of project"
                              ),
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  staticClass: "bg-color-grams-hair",
                                  attrs: {
                                    value: _vm.carbonClapData.totalProduction,
                                    disabled: "",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      {
                                        staticClass:
                                          "bg-color-north-wind text-color-white",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(259, "days")) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "1" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(101, "No. of episodes"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.carbonClapData.numberOfEpisode,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(
                                102,
                                "Film length/ avg episode length"
                              ),
                            },
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  staticClass: "bg-color-grams-hair",
                                  attrs: {
                                    value: _vm.carbonClapData.filmLength,
                                    disabled: "",
                                  },
                                }),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      {
                                        staticClass:
                                          "bg-color-north-wind text-color-white",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.FormMSG(104, "min")) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(103, "Shooting format"),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.optionForFormat,
                                id: "optionForFormat",
                                disabled: "",
                              },
                              model: {
                                value: _vm.carbonClapData.shootingFormat,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.carbonClapData,
                                    "shootingFormat",
                                    $$v
                                  )
                                },
                                expression: "carbonClapData.shootingFormat",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(73, "Budget") } },
                          [
                            _c(
                              "b-input-group",
                              [
                                _vm.isEnglish
                                  ? _c("VueNumberFormat", {
                                      staticClass:
                                        "form-control bg-color-grams-hair",
                                      attrs: {
                                        options: {
                                          precision: 2,
                                          prefix: "",
                                          isInteger: false,
                                          decimal: ".",
                                          thousand: ",",
                                        },
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.carbonClapData.budget,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.carbonClapData,
                                            "budget",
                                            $$v
                                          )
                                        },
                                        expression: "carbonClapData.budget",
                                      },
                                    })
                                  : _c("VueNumberFormat", {
                                      staticClass:
                                        "form-control bg-color-grams-hair",
                                      attrs: {
                                        options: {
                                          precision: 2,
                                          prefix: "",
                                          isInteger: false,
                                          decimal: ",",
                                          thousand: ".",
                                        },
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.carbonClapData.budget,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.carbonClapData,
                                            "budget",
                                            $$v
                                          )
                                        },
                                        expression: "carbonClapData.budget",
                                      },
                                    }),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c(
                                      "b-input-group-text",
                                      {
                                        staticClass:
                                          "bg-color-north-wind text-color-white",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.currencySymbol) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: _vm.FormMSG(74, "Currency") } },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.projectCurrency,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(105, "Project start date"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { value: _vm.startDate, disabled: "" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(106, "Project delivery date"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { value: _vm.endDate, disabled: "" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "2" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(
                                107,
                                "Majority producing country"
                              ),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "bg-color-grams-hair",
                              attrs: {
                                value: _vm.carbonClapData.country,
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "5" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.FormMSG(
                                108,
                                "Country of co-production"
                              ),
                            },
                          },
                          [
                            _c("v-select", {
                              staticClass: "bg-color-grams-hair",
                              attrs: { multiple: "", disabled: "" },
                              model: {
                                value: _vm.countriesProduction,
                                callback: function ($$v) {
                                  _vm.countriesProduction = $$v
                                },
                                expression: "countriesProduction",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }