var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(330, "Management") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            331,
                            "Figures shown have been pulled from the Waste, Accommodations and Menus screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(332, "Spending")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        333,
                                        "Total spend on small consumables & misc"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _vm.isEnglish
                                          ? _c("VueNumberFormat", {
                                              staticClass:
                                                "form-control bg-color-grams-hair",
                                              attrs: {
                                                options: {
                                                  precision: 2,
                                                  prefix: "",
                                                  isInteger: false,
                                                  decimal: ".",
                                                  thousand: ",",
                                                },
                                                disabled: _vm.isDisable,
                                              },
                                              model: {
                                                value:
                                                  _vm.management
                                                    .setSmallConsumableTotal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.management,
                                                    "setSmallConsumableTotal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "management.setSmallConsumableTotal",
                                              },
                                            })
                                          : _c("VueNumberFormat", {
                                              staticClass:
                                                "form-control bg-color-grams-hair",
                                              attrs: {
                                                options: {
                                                  precision: 2,
                                                  prefix: "",
                                                  isInteger: false,
                                                  decimal: ",",
                                                  thousand: ".",
                                                },
                                                disabled: _vm.isDisable,
                                              },
                                              model: {
                                                value:
                                                  _vm.management
                                                    .setSmallConsumableTotal,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.management,
                                                    "setSmallConsumableTotal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "management.setSmallConsumableTotal",
                                              },
                                            }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(334, "Waste")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(335, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              264,
                                              "Cardboard"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(336, "Measurement"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.number_format(
                                              _vm.management
                                                .setCardboardWasteTotal,
                                              0,
                                              ".",
                                              ","
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(365, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "pt-17", attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(495, "Steel"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "pt-17", attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.number_format(
                                              _vm.management.setSteelWasteTotal,
                                              0,
                                              ".",
                                              ","
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(365, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(497, "Wood"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.number_format(
                                              _vm.management.setWoodWasteTotal,
                                              0,
                                              ".",
                                              ","
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(365, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(496, "Mixed"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.number_format(
                                              _vm.management.setMixedWasteTotal,
                                              0,
                                              ".",
                                              ","
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(365, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(270, "Plastic"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.number_format(
                                              _vm.management
                                                .setPlasticWasteTotal,
                                              0,
                                              ".",
                                              ","
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(365, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(337, "Accomodations")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(338, "Total") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass: "br-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value: _vm.management.nbOvernight,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "nbOvernight",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.nbOvernight",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue bt-light-starlight bb-light-starlight pr-3",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "ExternalLink"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 16 },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(339, "nights")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(340, "Meals")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(335, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(489, "Red meat"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(341, "Total") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.management.nbRedMeatCatering,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "nbRedMeatCatering",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.nbRedMeatCatering",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(342, "meals")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              490,
                                              "Fish/ white meat"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.management
                                                .nbWhiteMeatCatering,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "nbWhiteMeatCatering",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.nbWhiteMeatCatering",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(342, "meals")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              491,
                                              "Vegetarian/ vegan"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.management
                                                .nbVegetarianCatering,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "nbVegetarianCatering",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.nbVegetarianCatering",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(342, "meals")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              498,
                                              "Not specified"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.management
                                                .nbNotSpecifiedCatering,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.management,
                                                "nbNotSpecifiedCatering",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "management.nbNotSpecifiedCatering",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(342, "meals")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }