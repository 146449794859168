var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(350, "Technical") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            351,
                            "Figures shown have been pulled from the Waste, Rentals and Transportation screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(352, "Waste")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(353, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              560,
                                              "Batteries disposable"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(354, "Total") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair": _vm.isDisable,
                                      },
                                      attrs: { disabled: _vm.isDisable },
                                      model: {
                                        value: _vm.technical.nbrBatteriesThrown,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.technical,
                                            "nbrBatteriesThrown",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "technical.nbrBatteriesThrown",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(355, "Costs")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(356, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              625,
                                              "Technical rentals"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(357, "Amount"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical.amountCameraRental,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "amountCameraRental",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.amountCameraRental",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              626,
                                              "Lighting rentals"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical
                                                .amountLightingRental,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "amountLightingRental",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.amountLightingRental",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              627,
                                              "Machinery rentals"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical
                                                .amountMachineryRental,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "amountMachineryRental",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.amountMachineryRental",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              628,
                                              "Sound rentals"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical
                                                .amountSoundMaterialRental,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "amountSoundMaterialRental",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.amountSoundMaterialRental",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              629,
                                              "Films and carriers"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical
                                                .amountOfFilmsSupport,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "amountOfFilmsSupport",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.amountOfFilmsSupport",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              630,
                                              "Special effects"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical
                                                .amountOfSpecialEffect,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "amountOfSpecialEffect",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.amountOfSpecialEffect",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.currencySymbol) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(358, "Transportation")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(359, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              631,
                                              "Canteens and coaches"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(360, "Distance"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value: _vm.technical.sumCarsLogesKm,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "sumCarsLogesKm",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.sumCarsLogesKm",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.distanceUnit) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(361, "Average size"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair": _vm.isDisable,
                                      },
                                      attrs: { disabled: _vm.isDisable },
                                      model: {
                                        value: _vm.technical.sizeOfCarLoge,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.technical,
                                            "sizeOfCarLoge",
                                            $$v
                                          )
                                        },
                                        expression: "technical.sizeOfCarLoge",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        362,
                                        "Canteen generator"
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair": _vm.isDisable,
                                      },
                                      attrs: { disabled: _vm.isDisable },
                                      model: {
                                        value:
                                          _vm.technical.energyTypeOfCarLoge,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.technical,
                                            "energyTypeOfCarLoge",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "technical.energyTypeOfCarLoge",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        363,
                                        "Generator fuel used"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.technical.volumeEnergyCarLoge,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.technical,
                                                "volumeEnergyCarLoge",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "technical.volumeEnergyCarLoge",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" L ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              680,
                                              "Car diesel"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.technical.carDiesel,
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(_vm._s(_vm.distanceUnit))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              691,
                                              "Car electric"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.technical.carElectric,
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(_vm._s(_vm.distanceUnit))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69154,
                                              "Car gasoline"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.technical.carGasoline,
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(_vm._s(_vm.distanceUnit))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69156,
                                              "Moto > 250cc"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.technical.motoLarge,
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(_vm._s(_vm.distanceUnit))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(359, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69132,
                                              "Ultralight aircraft class 2"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        35999,
                                        "Flight duration"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.convertMinutesToHours(
                                              _vm.technical.ulmPlaneClassTwo
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _c("Clock", {
                                                  attrs: { size: 15 },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69141,
                                              "Ultralight aircraft class 3"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.convertMinutesToHours(
                                              _vm.technical.ulmPlaneClassThree
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _c("Clock", {
                                                  attrs: { size: 15 },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              69681,
                                              "Helicopter"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.convertMinutesToHours(
                                              _vm.technical.helicopter
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _c("Clock", {
                                                  attrs: { size: 15 },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }