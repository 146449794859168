<template>
	<custom-accordion
		:title="FormMSG(236, 'Locations')"
		use-external-value
		:external-value="toggleCollapse"
		:is-loading="loading"
		@custom-accordion:click="handleCustomAccordionClick"
	>
		<template #content>
			<div>
				<div v-for="(location, index) in locationsData.locations" :key="index">
					<div v-if="index === 0" class="d-flex mb-2">
						<div class="text-color-blue-streak pt-1">
							<component :is="getLucideIcon('Info')" :size="16" />
						</div>
						<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14">
							{{
								FormMSG(
									237,
									'Filming locations shown have been pulled from the Locations setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a location go to the Locations screen, if you do not have access, consult the Production department.'
								)
							}}
						</div>
					</div>
					<div class="ml-4 mt-4 w-90">
						<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
								{{ FormMSG(238, 'General') }}
							</legend>
							<div class="pt-3 pb-4">
								<b-row>
									<b-col cols="4">
										<b-form-group :label="FormMSG(241, 'Name')">
											<b-input-group>
												<b-form-input
													:value="location.setName"
													class="br-none"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
														<button class="btn-transparent text-color-rhapsody-in-blue">
															<component :is="getLucideIcon('ExternalLink')" :size="16" />
														</button>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(242, 'Type of decor')">
											<b-form-input
												:value="getLocationType(location.type)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="3">
										<b-form-group :label="FormMSG(243, 'Country')">
											<b-form-input
												:value="location.address5.countryCode"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="1" class="pr-0">
										<b-form-group :label="FormMSG(244, 'Filming area')">
											<b-input-group>
												<b-form-input
													:value="location.filmingArea"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(224, 'm2') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(245, 'No. of days of use')">
											<b-input-group>
												<b-form-input
													:value="differenceDate(location.getInDate, location.getOutDate)"
													class="bg-color-grams-hair"
													disabled
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(259, 'days') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="2" class="pl-0">
										<b-form-group :label="FormMSG(246, 'Energy saving plan')">
											<b-form-input
												:value="getEnergySavingLabel(location.energySavingPlan)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(247, 'Electricity consumption')">
											<b-input-group>
												<b-form-input
													:value="location.elecMeterEnd"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(225, 'kWh') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="1" class="pr-0">
										<b-form-group :label="FormMSG(248, 'Generator type')">
											<b-form-input
												:value="getGeneratorTypeOptions(location.generatorFuelType)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(249, 'Generator consumption')">
											<b-input-group>
												<b-form-input
													v-model="location.generatorVolumeUsed"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white"> L </b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getLocations } from '@/cruds/locations.crud';
import { store } from '@/store';

export default {
	name: 'ReportingLocations',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion
	},

	props: {
		disableFields: { type: Boolean, default: false },
		locationsData: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			locations: [],
			toggleCollapse: false,
			loading: false
		};
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		energySavingOptions() {
			return this.FormMenu(1359);
		}
	},

	methods: {
		getEnergySavingLabel(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1359, value);
		},
		getGeneratorTypeOptions(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1402, value);
		},
		differenceDate(date1, date2) {
			date1 = new Date(date1);
			date2 = new Date(date2);

			let differenceInTime = date2.getTime() - date1.getTime();

			let differenceInDays = differenceInTime / (1000 * 3600 * 24);

			return differenceInDays;
		},
		getLocationType(value) {
			if (!store.isPme()) {
				return this.GetTextFromMenuNumberAndMenuValue(1026, value);
			}
			return this.GetTextFromMenuNumberAndMenuValue(1322, value);
		},
		async handleCustomAccordionClick(payload) {
			this.toggleCollapse = !payload;
		},
		async getLocations() {
			await getLocations({
				startDate: store.state.myProject.startDate,
				endDate: store.state.myProject.endDate,
				status: -1
			})
				.then((result) => {
					this.locations = result;
				})
				.catch((error) => {
					this.$emit('locations:error', error);
				});
		}
	}
};
</script>

<style scoped></style>
