var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: {
      title: _vm.FormMSG(125, "Premises"),
      "use-external-value": "",
      "external-value": _vm.toggleCollapse,
      "is-loading": _vm.loading,
    },
    on: { "custom-accordion:click": _vm.handleCustomAccordionClick },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              _vm._l(_vm.premises, function (premise, index) {
                return _c("div", { key: index }, [
                  index === 0
                    ? _c("div", { staticClass: "d-flex mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-color-blue-streak pt-1" },
                          [
                            _c(_vm.getLucideIcon("Info"), {
                              tag: "component",
                              attrs: { size: 16 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    126,
                                    "Premises are rented office and studio spaces that are not related to individual filming locations.  Premises shown have been pulled from the main project setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a premises go to Configuration > Project, Premises tab, if you do not have access, consult the Production department."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "ml-4 w-85 mt-4" },
                    [
                      _c(
                        "b-card",
                        { staticClass: "pb-5", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "fs-18 fw-400 text-color-rhapsody-in-blue",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(254, "Premises")) +
                                        " " +
                                        _vm._s(index + 1)
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-5" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(214, "Name"),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value: premise.setName,
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              215,
                                              "Type of premises"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value: _vm.getTypePremise(
                                                premise.type
                                              ),
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(216, "Country"),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value:
                                                premise.address5.countryCode,
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              217,
                                              "Area of premises"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                },
                                                attrs: {
                                                  value: premise.filmingArea,
                                                  disabled: _vm.isDisable,
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              224,
                                                              "m2"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              218,
                                              "Total occupation"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                },
                                                attrs: {
                                                  value:
                                                    premise.totNbOfDaysOccupation,
                                                  disabled: _vm.isDisable,
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              259,
                                                              "days"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              219,
                                              "Energy consumption"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                },
                                                attrs: {
                                                  value: _vm.sumEnergy(
                                                    premise.carbon.elecMeterEnd,
                                                    premise.carbon
                                                      .elecMeterStart
                                                  ),
                                                  disabled: _vm.isDisable,
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              225,
                                                              "kWh"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              220,
                                              "Total no. of man days"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                },
                                                attrs: {
                                                  value: premise.totNbOfManDays,
                                                  disabled: _vm.isDisable,
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              259,
                                                              "days"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              221,
                                              "Air conditioners"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value:
                                                premise.nbOfAirConditioners,
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              222,
                                              "Energy sobriety plan"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value:
                                                _vm.getEnergySavingPlanLabel(
                                                  premise.carbon.type
                                                ),
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              223,
                                              "Green energy supplier"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value: _vm.getYesOrNoLabel(
                                                premise.carbon
                                                  .greenEnergySupplier
                                              ),
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "1" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              229,
                                              "Sorted waste"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value: _vm.getYesOrNoLabel(
                                                premise.carbon.sortedWaste
                                              ),
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              228,
                                              "Uses recycled paper"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            class: {
                                              "bg-color-grams-hair":
                                                _vm.isDisable,
                                            },
                                            attrs: {
                                              value: _vm.getYesOrNoLabel(
                                                premise.carbon.recycledPaper
                                              ),
                                              disabled: _vm.isDisable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              226,
                                              "Paper consumption"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                },
                                                attrs: {
                                                  value:
                                                    premise.carbon.paperPrinted,
                                                  disabled: _vm.isDisable,
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "bg-color-north-wind text-color-white",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              227,
                                                              "sheets"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "mt-4" },
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "my-0 py-0 pb-0",
                                        class: `${
                                          _vm.$screen.width <= 576
                                            ? "card-inside"
                                            : "scheduler-border"
                                        }`,
                                      },
                                      [
                                        _c(
                                          "legend",
                                          {
                                            staticClass:
                                              "text-color-rhapsody-in-blue-2 fw-400",
                                            class: `${
                                              _vm.$screen.width <= 576
                                                ? "card-inside"
                                                : "scheduler-border"
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(625, "Equipment")
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        premise.computerEquipments.length > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "pt-3 pb-3" },
                                              _vm._l(
                                                premise.computerEquipments,
                                                function (equipment, index2) {
                                                  return _c(
                                                    "b-row",
                                                    {
                                                      key: index + "" + index2,
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  index2 > 0
                                                                    ? ""
                                                                    : _vm.FormMSG(
                                                                        230,
                                                                        "Type"
                                                                      ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group",
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      class: {
                                                                        "bg-color-grams-hair":
                                                                          _vm.isDisable,
                                                                      },
                                                                      attrs: {
                                                                        value:
                                                                          _vm.getEquipmentType(
                                                                            equipment.type
                                                                          ),
                                                                        disabled:
                                                                          _vm.isDisable,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "b-input-group-append",
                                                                    [
                                                                      _c(
                                                                        "b-input-group-text",
                                                                        {
                                                                          staticClass:
                                                                            "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn-transparent text-color-rhapsody-in-blue",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  "ExternalLink"
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      size: 16,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "pl-0",
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  index2 > 0
                                                                    ? ""
                                                                    : _vm.FormMSG(
                                                                        264,
                                                                        "Quantity"
                                                                      ),
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  class: {
                                                                    "bg-color-grams-hair":
                                                                      _vm.isDisable,
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isDisable,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      equipment.quantity,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          equipment,
                                                                          "quantity",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "equipment.quantity",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "pt-3 pb-3" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            511,
                                                            "No equipment"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }