<template>
	<custom-accordion :title="FormMSG(370, 'Post Production')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								371,
								'Figures shown have been pulled from the Timesheets screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(372, 'Employment') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<b-form-group :label="FormMSG(373, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(621, 'Assembly')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(374, 'Total worked')">
										<b-input-group>
											<b-form-input v-model="postProduction.numDaysVideoEditing" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> days </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(375, 'Country')">
										<b-form-input v-model="postProduction.videoEditingCountry" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(631, 'Sound')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.numDaysSoundEditing" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> days </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.soundEditingCountry" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(641, 'Digital visual effects')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.numDaysVfx" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> days </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.vfxCountry" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(651, 'Calibration')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.numDaysColorGrading" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> days </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.colorGradingCountry" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(661, 'Laboratory')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.numDaysMasteringAndLab" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> days </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.masteringAndLabCountry" />
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(390, 'Archiving') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<b-form-group :label="FormMSG(391, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(708, 'Physical LTO')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(392, 'Total')">
										<b-input-group>
											<b-form-input v-model="postProduction.dataArchivedLTO" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> gb </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(393, 'No. of copies')">
										<b-form-input v-model="postProduction.numCopiesArchivedLTO" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(709, 'Physical HDD')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.dataArchivedHDD" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> gb </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.numCopiesArchivedHDD" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(654, 'Cloud')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input v-model="postProduction.dataArchivedOnCloud" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> gb </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-form-input v-model="postProduction.numCopiesArchivedOnCloud" />
									</b-form-group>
								</b-col>
								<b-col cols="4">
									<div class="w-55">
										<b-row>
											<b-col class="pl-0">
												<b-form-group :label="FormMSG(395, 'Geo redundancy')" label-cols="3">
													<b-form-input style="width: 135px" />
												</b-form-group>
											</b-col>
										</b-row>
									</div>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';

export default {
	name: 'ReportingPostProduction',

	mixins: [languageMessages, globalMixin],

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	components: {
		CustomAccordion
	},

	data() {
		return {
			postProduction: {
				numDaysVideoEditing: 0,
				videoEditingCountry: '',
				numDaysSoundEditing: 0,
				soundEditingCountry: '',
				numDaysVfx: 0,
				vfxCountry: '',
				numDaysColorGrading: 0,
				colorGradingCountry: '',
				numDaysMasteringAndLab: 0,
				masteringAndLabCountry: '',
				dataArchivedLTO: 0,
				numCopiesArchivedLTO: 0,
				dataArchivedHDD: 0,
				numCopiesArchivedHDD: 0,
				dataArchivedOnCloud: 0,
				numCopiesArchivedOnCloud: 0
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal)) {
					this.postProduction.numDaysVideoEditing = newVal.numDaysVideoEditing;
					this.postProduction.videoEditingCountry = newVal.videoEditingCountry;
					this.postProduction.numDaysSoundEditing = newVal.numDaysSoundEditing;
					this.postProduction.soundEditingCountry = newVal.soundEditingCountry;
					this.postProduction.numDaysVfx = newVal.numDaysVfx;
					this.postProduction.vfxCountry = newVal.vfxCountry;
					this.postProduction.numDaysColorGrading = newVal.numDaysColorGrading;
					this.postProduction.colorGradingCountry = newVal.colorGradingCountry;
					this.postProduction.numDaysMasteringAndLab = newVal.numDaysMasteringAndLab;
					this.postProduction.masteringAndLabCountry = newVal.masteringAndLabCountry;
					this.postProduction.dataArchivedLTO = newVal.dataArchivedLTO;
					this.postProduction.numCopiesArchivedLTO = newVal.numCopiesArchivedLTO;
					this.postProduction.dataArchivedHDD = newVal.dataArchivedHDD;
					this.postProduction.numCopiesArchivedHDD = newVal.numCopiesArchivedHDD;
					this.postProduction.dataArchivedOnCloud = newVal.dataArchivedOnCloud;
					this.postProduction.numCopiesArchivedOnCloud = newVal.numCopiesArchivedOnCloud;
				}
			},
			immediate: true,
			deep: true
		},
		postProduction: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		}
	},

	methods: {
		emitChange(newVal) {
			this.postProduction.numDaysVideoEditing = +newVal.numDaysVideoEditing;
			this.postProduction.videoEditingCountry = newVal.videoEditingCountry;
			this.postProduction.numDaysSoundEditing = +newVal.numDaysSoundEditing;
			this.postProduction.soundEditingCountry = newVal.soundEditingCountry;
			this.postProduction.numDaysVfx = +newVal.numDaysVfx;
			this.postProduction.vfxCountry = newVal.vfxCountry;
			this.postProduction.numDaysColorGrading = +newVal.numDaysColorGrading;
			this.postProduction.colorGradingCountry = newVal.colorGradingCountry;
			this.postProduction.numDaysMasteringAndLab = +newVal.numDaysMasteringAndLab;
			this.postProduction.masteringAndLabCountry = newVal.masteringAndLabCountry;
			this.postProduction.dataArchivedLTO = +newVal.dataArchivedLTO;
			this.postProduction.numCopiesArchivedLTO = +newVal.numCopiesArchivedLTO;
			this.postProduction.dataArchivedHDD = +newVal.dataArchivedHDD;
			this.postProduction.numCopiesArchivedHDD = +newVal.numCopiesArchivedHDD;
			this.postProduction.dataArchivedOnCloud = +newVal.dataArchivedOnCloud;
			this.postProduction.numCopiesArchivedOnCloud = +newVal.numCopiesArchivedOnCloud;

			this.$emit('reporting-post-production:change', this.postProduction);
		}
	}
};
</script>

<style scoped></style>
