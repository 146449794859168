var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(270, "Decoration") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            271,
                            "Set Decoration figures shown have been pulled from the General Ledger and Waste screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(272, "Cost")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        273,
                                        "Cost of purchasing sets"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration
                                                .costPurchaseSetsTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "costPurchaseSetsTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.costPurchaseSetsTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        275,
                                        "Cost of renting sets"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration
                                                .costRentingSetsTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "costRentingSetsTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.costRentingSetsTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        294,
                                        "Cost of creation sets"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration
                                                .costCreationSetsTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "costCreationSetsTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.costCreationSetsTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(276, "Eco-designed"),
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.yesNoOptions },
                                      model: {
                                        value:
                                          _vm.decoration.ecoConceptionSetTotal,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.decoration,
                                            "ecoConceptionSetTotal",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "decoration.ecoConceptionSetTotal",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(293, "Sorted waste"),
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.yesNoOptions },
                                      model: {
                                        value: _vm.decoration.sortingWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.decoration,
                                            "sortingWaste",
                                            $$v
                                          )
                                        },
                                        expression: "decoration.sortingWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(278, "Waste")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(279, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(251, "Paint"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(280, "Measurement"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair br-none":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration
                                                .paintLiterWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "paintLiterWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.paintLiterWasteTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" L   ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              252,
                                              "Cardboard"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair br-none":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration
                                                .cardboardWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "cardboardWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.cardboardWasteTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(253, "Wood"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair br-none":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration.woodWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "woodWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.woodWasteTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(475, "Steel"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair br-none":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration.steelWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "steelWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.steelWasteTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(255, "Plastic"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair br-none":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.decoration.plasticWasteTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.decoration,
                                                "plasticWasteTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "decoration.plasticWasteTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }