var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(370, "Post Production") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            371,
                            "Figures shown have been pulled from the Timesheets screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(372, "Employment")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(373, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(621, "Assembly"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(374, "Total worked"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .numDaysVideoEditing,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "numDaysVideoEditing",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.numDaysVideoEditing",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" days ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(375, "Country"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .videoEditingCountry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "videoEditingCountry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.videoEditingCountry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(631, "Sound"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .numDaysSoundEditing,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "numDaysSoundEditing",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.numDaysSoundEditing",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" days ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .soundEditingCountry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "soundEditingCountry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.soundEditingCountry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              641,
                                              "Digital visual effects"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction.numDaysVfx,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "numDaysVfx",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.numDaysVfx",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" days ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value: _vm.postProduction.vfxCountry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "vfxCountry",
                                            $$v
                                          )
                                        },
                                        expression: "postProduction.vfxCountry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              651,
                                              "Calibration"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .numDaysColorGrading,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "numDaysColorGrading",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.numDaysColorGrading",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" days ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .colorGradingCountry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "colorGradingCountry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.colorGradingCountry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              661,
                                              "Laboratory"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .numDaysMasteringAndLab,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "numDaysMasteringAndLab",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.numDaysMasteringAndLab",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" days ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .masteringAndLabCountry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "masteringAndLabCountry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.masteringAndLabCountry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(390, "Archiving")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(391, "Type") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              708,
                                              "Physical LTO"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(392, "Total") },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .dataArchivedLTO,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "dataArchivedLTO",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.dataArchivedLTO",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" gb ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(393, "No. of copies"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .numCopiesArchivedLTO,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "numCopiesArchivedLTO",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.numCopiesArchivedLTO",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              709,
                                              "Physical HDD"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .dataArchivedHDD,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "dataArchivedHDD",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.dataArchivedHDD",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" gb ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .numCopiesArchivedHDD,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "numCopiesArchivedHDD",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.numCopiesArchivedHDD",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(654, "Cloud"),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          model: {
                                            value:
                                              _vm.postProduction
                                                .dataArchivedOnCloud,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postProduction,
                                                "dataArchivedOnCloud",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "postProduction.dataArchivedOnCloud",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [_vm._v(" gb ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("b-form-input", {
                                      model: {
                                        value:
                                          _vm.postProduction
                                            .numCopiesArchivedOnCloud,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.postProduction,
                                            "numCopiesArchivedOnCloud",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "postProduction.numCopiesArchivedOnCloud",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-col", { attrs: { cols: "4" } }, [
                              _c(
                                "div",
                                { staticClass: "w-55" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { staticClass: "pl-0" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  395,
                                                  "Geo redundancy"
                                                ),
                                                "label-cols": "3",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticStyle: { width: "135px" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }