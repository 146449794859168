var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(301, "Costumes, Hair & Makeup") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            302,
                            "Figures shown have been pulled from the General Ledger screen and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department."
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(272, "Cost")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        303,
                                        "Costume purchases"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value: _vm.costumes.costumeBuyTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.costumes,
                                                "costumeBuyTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "costumes.costumeBuyTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        304,
                                        "Cost of making costumes"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.costumes.costumeMakingTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.costumes,
                                                "costumeMakingTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "costumes.costumeMakingTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        305,
                                        "Costume rentals"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.costumes.costumeRentalTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.costumes,
                                                "costumeRentalTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "costumes.costumeRentalTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        306,
                                        "over 50% donated"
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.yesNoOptions },
                                      model: {
                                        value: _vm.costumes.costumeReuse,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.costumes,
                                            "costumeReuse",
                                            $$v
                                          )
                                        },
                                        expression: "costumes.costumeReuse",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        307,
                                        "Makeup purchases"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value: _vm.costumes.makeupTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.costumes,
                                                "makeupTotal",
                                                $$v
                                              )
                                            },
                                            expression: "costumes.makeupTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        308,
                                        "Hairdressing purchases"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c(
                                          "b-input-group-prepend",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass: "input-prepend",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c("currency-svg", {
                                                  attrs: {
                                                    color: "#06263E",
                                                    opacity: "0.85",
                                                    width: "15",
                                                    height: "15",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("b-form-input", {
                                          staticClass: "br-none bl-none",
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value: _vm.costumes.hairCostTotal,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.costumes,
                                                "hairCostTotal",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "costumes.hairCostTotal",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "input-group-text-bg-grams-hair",
                                                class: {
                                                  "bg-color-grams-hair":
                                                    _vm.isDisable,
                                                  "bg-color-white":
                                                    !_vm.isDisable,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }