var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: {
      title: _vm.FormMSG(236, "Locations"),
      "use-external-value": "",
      "external-value": _vm.toggleCollapse,
      "is-loading": _vm.loading,
    },
    on: { "custom-accordion:click": _vm.handleCustomAccordionClick },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              _vm._l(_vm.locationsData.locations, function (location, index) {
                return _c("div", { key: index }, [
                  index === 0
                    ? _c("div", { staticClass: "d-flex mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "text-color-blue-streak pt-1" },
                          [
                            _c(_vm.getLucideIcon("Info"), {
                              tag: "component",
                              attrs: { size: 16 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    237,
                                    "Filming locations shown have been pulled from the Locations setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a location go to the Locations screen, if you do not have access, consult the Production department."
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                    _c(
                      "fieldset",
                      {
                        staticClass: "my-0 py-0 pb-0",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _c(
                          "legend",
                          {
                            staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                            class: `${
                              _vm.$screen.width <= 576
                                ? "card-inside"
                                : "scheduler-border"
                            }`,
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(238, "General")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "pt-3 pb-4" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(241, "Name"),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "br-none",
                                              class: {
                                                "bg-color-grams-hair":
                                                  _vm.isDisable,
                                              },
                                              attrs: {
                                                value: location.setName,
                                                disabled: _vm.isDisable,
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn-transparent text-color-rhapsody-in-blue",
                                                      },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            "ExternalLink"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: { size: 16 },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            242,
                                            "Type of decor"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.getLocationType(
                                              location.type
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(243, "Country"),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value:
                                              location.address5.countryCode,
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pr-0", attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            244,
                                            "Filming area"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              class: {
                                                "bg-color-grams-hair":
                                                  _vm.isDisable,
                                              },
                                              attrs: {
                                                value: location.filmingArea,
                                                disabled: _vm.isDisable,
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "bg-color-north-wind text-color-white",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(224, "m2")
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            245,
                                            "No. of days of use"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "bg-color-grams-hair",
                                              attrs: {
                                                value: _vm.differenceDate(
                                                  location.getInDate,
                                                  location.getOutDate
                                                ),
                                                disabled: "",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "bg-color-north-wind text-color-white",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            259,
                                                            "days"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pl-0", attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            246,
                                            "Energy saving plan"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.getEnergySavingLabel(
                                              location.energySavingPlan
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            247,
                                            "Electricity consumption"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              class: {
                                                "bg-color-grams-hair":
                                                  _vm.isDisable,
                                              },
                                              attrs: {
                                                value: location.elecMeterEnd,
                                                disabled: _vm.isDisable,
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "bg-color-north-wind text-color-white",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            225,
                                                            "kWh"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { staticClass: "pr-0", attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            248,
                                            "Generator type"
                                          ),
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: {
                                            value: _vm.getGeneratorTypeOptions(
                                              location.generatorFuelType
                                            ),
                                            disabled: _vm.isDisable,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            249,
                                            "Generator consumption"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              class: {
                                                "bg-color-grams-hair":
                                                  _vm.isDisable,
                                              },
                                              attrs: {
                                                disabled: _vm.isDisable,
                                              },
                                              model: {
                                                value:
                                                  location.generatorVolumeUsed,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    location,
                                                    "generatorVolumeUsed",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "location.generatorVolumeUsed",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  {
                                                    staticClass:
                                                      "bg-color-north-wind text-color-white",
                                                  },
                                                  [_vm._v(" L ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }