var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("custom-accordion", {
    attrs: { title: _vm.FormMSG(320, "Transportation") },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "text-color-blue-streak pt-1" },
                  [
                    _c(_vm.getLucideIcon("Info"), {
                      tag: "component",
                      attrs: { size: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            321,
                            "Figures shown have been pulled from the Transportation and  General Ledger screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  "
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "ml-4 mt-4 w-90" }, [
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(322, "General")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "w-100" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      251,
                                                      "Car diesel"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                324,
                                                "Distance"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumDieselKm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumDieselKm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumDieselKm",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      252,
                                                      "Car petrol"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumPetrolKm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumPetrolKm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumPetrolKm",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      253,
                                                      "Car hybrid"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumHybridKm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumHybridKm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumHybridKm",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      254,
                                                      "Car electric"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumElectricKm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumElectricKm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumElectricKm",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      2540,
                                                      "Large Motorcycle"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumMotorcycleLarge,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumMotorcycleLarge",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumMotorcycleLarge",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      2541,
                                                      "Gasoline Scooter"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumScooterGasoline,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumScooterGasoline",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumScooterGasoline",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      2542,
                                                      "Electric Scooter"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                324,
                                                "Distance"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumScooterElectric,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumScooterElectric",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumScooterElectric",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      255,
                                                      "Taxi"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumTaxiKm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumTaxiKm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumTaxiKm",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      2590,
                                                      "Bus"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation.sumBus,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumBus",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumBus",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      2555,
                                                      "Intercity Train"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumIntercityTrain,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumIntercityTrain",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumIntercityTrain",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      256,
                                                      "Plane"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumPlaneKm,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumPlaneKm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumPlaneKm",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      2560,
                                                      "Private Jet"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumPrivateJet,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumPrivateJet",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumPrivateJet",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                323,
                                                "Transportation type"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      376,
                                                      "Train"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                324,
                                                "Distance"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumTrain,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumTrain",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumTrain",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  staticClass:
                                                    "bg-color-grams-hair br-none",
                                                  attrs: {
                                                    value: _vm.FormMSG(
                                                      3766,
                                                      "Ferry Boat"
                                                    ),
                                                    disabled: "",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-grams-hair input-group-text-bg-grams-hair",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-rhapsody-in-blue",
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "ExternalLink"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("b-form-input", {
                                                  class: {
                                                    "bg-color-grams-hair":
                                                      _vm.isDisable,
                                                  },
                                                  attrs: {
                                                    disabled: _vm.isDisable,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.transportation
                                                        .sumFerryBoat,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.transportation,
                                                        "sumFerryBoat",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "transportation.sumFerryBoat",
                                                  },
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  [
                                                    _c(
                                                      "b-input-group-text",
                                                      {
                                                        staticClass:
                                                          "bg-color-north-wind text-color-white",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.distanceUnit
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "fieldset",
                  {
                    staticClass: "my-0 py-0 pb-0 mt-4",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2 fw-400",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(326, "Freight")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "pt-3 pb-4" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        323,
                                        "Transportation type"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              261,
                                              "Freight truck < 7t"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(324, "Distance"),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightSmallTruckKm,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightSmallTruckKm",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightSmallTruckKm",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(_vm.distanceUnit) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        375,
                                        "Material weight"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightSmallTruckKg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightSmallTruckKg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightSmallTruckKg",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              262,
                                              "Freight truck > 7t"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightLargeTruckKm,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightLargeTruckKm",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightLargeTruckKm",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(_vm.distanceUnit) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightLargeTruckKg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightLargeTruckKg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightLargeTruckKg",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              26289,
                                              "Freight Train"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightTrain,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightTrain",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightTrain",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(_vm.distanceUnit) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightTrainKg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightTrainKg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightTrainKg",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              26287,
                                              "Freight Plane"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightPlane,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightPlane",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightPlane",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(_vm.distanceUnit) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumFreightPlaneKg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumFreightPlaneKg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumFreightPlaneKg",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "bg-color-grams-hair br-none",
                                          attrs: {
                                            value: _vm.FormMSG(
                                              26285,
                                              "Container Ship"
                                            ),
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-grams-hair input-group-text-bg-grams-hair",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "ExternalLink"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: { size: 16 },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumContainerShip,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumContainerShip",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumContainerShip",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(_vm.distanceUnit) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          class: {
                                            "bg-color-grams-hair":
                                              _vm.isDisable,
                                          },
                                          attrs: { disabled: _vm.isDisable },
                                          model: {
                                            value:
                                              _vm.transportation
                                                .sumContainerShipKg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.transportation,
                                                "sumContainerShipKg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "transportation.sumContainerShipKg",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          [
                                            _c(
                                              "b-input-group-text",
                                              {
                                                staticClass:
                                                  "bg-color-north-wind text-color-white",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(283, "kg")
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }