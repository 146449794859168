<template>
	<custom-accordion :title="FormMSG(320, 'Transportation')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								321,
								'Figures shown have been pulled from the Transportation and  General Ledger screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(322, 'General') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row class="w-100">
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(251, 'Car diesel')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(324, 'Distance')">
												<b-input-group>
													<b-form-input
														v-model="transportation.sumDieselKm"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(252, 'Car petrol')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumPetrolKm"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(253, 'Car hybrid')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumHybridKm"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(254, 'Car electric')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumElectricKm"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(2540, 'Large Motorcycle')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumMotorcycleLarge"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(2541, 'Gasoline Scooter')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumScooterGasoline"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(2542, 'Electric Scooter')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(324, 'Distance')">
												<b-input-group>
													<b-form-input
														v-model="transportation.sumScooterElectric"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(255, 'Taxi')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumTaxiKm"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(2590, 'Bus')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumBus"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(2555, 'Intercity Train')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumIntercityTrain"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(256, 'Plane')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumPlaneKm"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(2560, 'Private Jet')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumPrivateJet"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col cols="4">
									<b-row>
										<b-col cols="6">
											<b-form-group :label="FormMSG(323, 'Transportation type')">
												<b-input-group>
													<b-form-input :value="FormMSG(376, 'Train')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group :label="FormMSG(324, 'Distance')">
												<b-input-group>
													<b-form-input
														v-model="transportation.sumTrain"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input :value="FormMSG(3766, 'Ferry Boat')" class="bg-color-grams-hair br-none" disabled />
													<b-input-group-append>
														<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
															<button class="btn-transparent text-color-rhapsody-in-blue">
																<component :is="getLucideIcon('ExternalLink')" :size="16" />
															</button>
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group>
												<b-input-group>
													<b-form-input
														v-model="transportation.sumFerryBoat"
														:class="{ 'bg-color-grams-hair': isDisable }"
														:disabled="isDisable"
													/>
													<b-input-group-append>
														<b-input-group-text class="bg-color-north-wind text-color-white">
															{{ distanceUnit }}
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(326, 'Freight') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(323, 'Transportation type')">
										<b-input-group>
											<b-form-input :value="FormMSG(261, 'Freight truck < 7t')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(324, 'Distance')">
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightSmallTruckKm"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ distanceUnit }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(375, 'Material weight')">
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightSmallTruckKg"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(262, 'Freight truck > 7t')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightLargeTruckKm"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ distanceUnit }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightLargeTruckKg"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(26289, 'Freight Train')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightTrain"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ distanceUnit }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightTrainKg"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(26287, 'Freight Plane')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightPlane"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ distanceUnit }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumFreightPlaneKg"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(26285, 'Container Ship')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumContainerShip"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ distanceUnit }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="transportation.sumContainerShipKg"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';

export default {
	name: 'ReportingTransportation',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: false },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			transportation: {
				sumDieselKm: 0,
				sumPetrolKm: 0,
				sumHybridKm: 0,
				sumElectricKm: 0,
				sumTaxiKm: 0,
				sumPlaneKm: 0,
				sumFreightSmallTruckKm: 0,
				sumFreightSmallTruckKg: 0,
				sumFreightLargeTruckKm: 0,
				sumFreightLargeTruckKg: 0,
				sumTrain: 0,
				sumFreightLargeTruckKg: 0,
				sumMotorcycleLarge: 0,
				sumScooterGasoline: 0,
				sumScooterElectric: 0,
				sumPrivateJet: 0,
				sumIntercityTrain: 0,
				sumBus: 0,
				sumFerryBoat: 0,
				sumFreightTrain: 0,
				sumFreightPlane: 0,
				sumContainerShip: 0,
				sumFreightTrainKg: 0,
				sumFreightPlaneKg: 0,
				sumContainerShipKg: 0
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.transportation.sumDieselKm = newVal.sumDieselKm;
					this.transportation.sumPetrolKm = newVal.sumPetrolKm;
					this.transportation.sumHybridKm = newVal.sumHybridKm;
					this.transportation.sumElectricKm = newVal.sumElectricKm;
					this.transportation.sumTaxiKm = newVal.sumTaxiKm;
					this.transportation.sumPlaneKm = newVal.sumPlaneKm;
					this.transportation.sumFreightSmallTruckKm = newVal.sumFreightSmallTruckKm;
					this.transportation.sumFreightSmallTruckKg = newVal.sumFreightSmallTruckKg;
					this.transportation.sumFreightLargeTruckKm = newVal.sumFreightLargeTruckKm;
					this.transportation.sumFreightLargeTruckKg = newVal.sumFreightLargeTruckKg;
					this.transportation.sumTrain = newVal.sumTrain;
					this.transportation.sumFreightLargeTruckKg = newVal.sumFreightLargeTruckKg;
					this.transportation.sumMotorcycleLarge = newVal.sumMotorcycleLarge;
					this.transportation.sumScooterGasoline = newVal.sumScooterGasoline;
					this.transportation.sumScooterElectric = newVal.sumScooterElectric;
					this.transportation.sumPrivateJet = newVal.sumPrivateJet;
					this.transportation.sumIntercityTrain = newVal.sumIntercityTrain;
					this.transportation.sumBus = newVal.sumBus;
					this.transportation.sumFerryBoat = newVal.sumFerryBoat;
					this.transportation.sumFreightTrain = newVal.sumFreightTrain;
					this.transportation.sumFreightPlane = newVal.sumFreightPlane;
					this.transportation.sumContainerShip = newVal.sumContainerShip;
					this.transportation.sumFreightTrainKg = newVal.sumFreightTrainKg;
					this.transportation.sumFreightPlaneKg = newVal.sumFreightPlaneKg;
					this.transportation.sumContainerShipKg = newVal.sumContainerShipKg;
				}
			},
			immediate: true,
			deep: true
		},
		transportation: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		}
	},

	methods: {
		emitChange(newVal) {
			this.transportation.sumDieselKm = parseFloat(newVal.sumDieselKm);
			this.transportation.sumPetrolKm = parseFloat(newVal.sumPetrolKm);
			this.transportation.sumHybridKm = parseFloat(newVal.sumHybridKm);
			this.transportation.sumElectricKm = parseFloat(newVal.sumElectricKm);
			this.transportation.sumTaxiKm = parseFloat(newVal.sumTaxiKm);
			this.transportation.sumPlaneKm = parseFloat(newVal.sumPlaneKm);
			this.transportation.sumFreightSmallTruckKm = parseFloat(newVal.sumFreightSmallTruckKm);
			this.transportation.sumFreightSmallTruckKg = parseFloat(newVal.sumFreightSmallTruckKg);
			this.transportation.sumFreightLargeTruckKm = parseFloat(newVal.sumFreightLargeTruckKm);
			this.transportation.sumFreightLargeTruckKg = parseFloat(newVal.sumFreightLargeTruckKg);
			this.transportation.sumTrain = parseFloat(newVal.sumTrain);

			this.$emit('reporting-transportation:change', this.transportation);
		}
	}
};
</script>

<style scoped></style>
