<template>
	<custom-accordion :title="FormMSG(270, 'Decoration')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								271,
								'Set Decoration figures shown have been pulled from the General Ledger and Waste screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(272, 'Cost') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(273, 'Cost of purchasing sets')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="decoration.costPurchaseSetsTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{
														'bg-color-grams-hair': isDisable,
														'bg-color-white': !isDisable
													}"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(274, 'Cost of making sets')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="decoration.costMakingSetsTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col> -->
								<b-col cols="2">
									<b-form-group :label="FormMSG(275, 'Cost of renting sets')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="decoration.costRentingSetsTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(294, 'Cost of creation sets')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="decoration.costCreationSetsTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(276, 'Eco-designed')">
										<b-form-select v-model="decoration.ecoConceptionSetTotal" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(293, 'Sorted waste')">
										<b-form-select v-model="decoration.sortingWaste" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
								<!-- <b-col cols="2">
									<b-form-group :label="FormMSG(295, 'Sorted material')">
										<b-form-select v-model="decoration.materialSorted" :options="yesNoOptions" />
									</b-form-group>
								</b-col> -->
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(278, 'Waste') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(279, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(251, 'Paint')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(280, 'Measurement')">
										<b-input-group>
											<b-form-input
												v-model="decoration.paintLiterWasteTotal"
												:class="{ 'bg-color-grams-hair br-none': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> L&nbsp;&nbsp; </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(252, 'Cardboard')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="decoration.cardboardWasteTotal"
												:class="{ 'bg-color-grams-hair br-none': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(253, 'Wood')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>

								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="decoration.woodWasteTotal"
												:class="{ 'bg-color-grams-hair br-none': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(475, 'Steel')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="decoration.steelWasteTotal"
												:class="{ 'bg-color-grams-hair br-none': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(255, 'Plastic')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="decoration.plasticWasteTotal"
												:class="{ 'bg-color-grams-hair br-none': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(283, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CurrencySvg from '@/components/Svg/Currency';
import { validationMixin } from 'vuelidate';
// import { numeric } from 'vuelidate/lib/validators';

import _ from 'lodash';

export default {
	name: 'ReportingDecoration',

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		CustomAccordion,
		CurrencySvg
	},

	data() {
		return {
			decoration: {
				costPurchaseSetsTotal: 0,
				// costMakingSetsTotal: 0,
				costRentingSetsTotal: 0,
				ecoConceptionSetTotal: false,
				sortingWaste: false,
				paintLiterWasteTotal: 0,
				cardboardWasteTotal: 0,
				woodWasteTotal: 0,
				steelWasteTotal: 0,
				plasticWasteTotal: 0,
				costCreationSetsTotal: 0,
				materialSorted: false
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.decoration.costPurchaseSetsTotal = newVal.costPurchaseSetsTotal;
					// this.decoration.costMakingSetsTotal = newVal.costMakingSetsTotal;
					this.decoration.costRentingSetsTotal = newVal.costRentingSetsTotal;
					this.decoration.ecoConceptionSetTotal = newVal.ecoConceptionSetTotal;
					this.decoration.paintLiterWasteTotal = newVal.paintLiterWasteTotal;
					this.decoration.cardboardWasteTotal = newVal.cardboardWasteTotal;
					this.decoration.woodWasteTotal = newVal.woodWasteTotal;
					this.decoration.steelWasteTotal = newVal.steelWasteTotal;
					this.decoration.plasticWasteTotal = newVal.plasticWasteTotal;
					this.decoration.sortingWaste = newVal.sortingWaste;
					this.decoration.costCreationSetsTotal = newVal.costCreationSetsTotal;
					this.decoration.materialSorted = newVal.materialSorted;
				}
			},
			immediate: true,
			deep: true
		},
		decoration: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		errorMessage() {
			return this.FormMSG(89, 'The value must be numeric');
		},
		isDisable() {
			return this.disableFields;
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		}
	},

	methods: {
		emitChange(newVal) {
			this.decoration.costPurchaseSetsTotal = parseFloat(newVal.costPurchaseSetsTotal);
			// this.decoration.costMakingSetsTotal = parseFloat(newVal.costMakingSetsTotal);
			this.decoration.costRentingSetsTotal = parseFloat(newVal.costRentingSetsTotal);
			this.decoration.ecoConceptionSetTotal = newVal.ecoConceptionSetTotal;
			this.decoration.paintLiterWasteTotal = parseFloat(newVal.paintLiterWasteTotal);
			this.decoration.cardboardWasteTotal = parseFloat(newVal.cardboardWasteTotal);
			this.decoration.woodWasteTotal = parseFloat(newVal.woodWasteTotal);
			this.decoration.steelWasteTotal = parseFloat(newVal.steelWasteTotal);
			this.decoration.plasticWasteTotal = parseFloat(newVal.plasticWasteTotal);
			this.decoration.sortingWaste = newVal.sortingWaste;
			this.decoration.costCreationSetsTotal = parseFloat(newVal.costCreationSetsTotal);
			this.decoration.materialSorted = newVal.materialSorted;

			this.$emit('reporting-decoration:change', this.decoration);
		}
	}
};
</script>

<style scoped></style>
