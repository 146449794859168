<template>
	<b-card no-body class="pt-0 border-none">
		<b-card-text class="pb-3">
			<b-row v-if="false">
				<b-col>
					<button type="button" class="btn-transparent text-color-clematis-blue fs-14 mr-3">
						<component :is="getLucideIcon('Filter')" :size="16" />
						{{ FormMSG(54, 'Filter') }}
					</button>
					<button type="button" class="btn-transparent text-color-clematis-blue fs-14 mr-3">
						<component :is="getLucideIcon('ArrowUpDown')" :size="16" />
						{{ FormMSG(55, 'Sort') }}
					</button>
					<button type="button" class="btn-transparent text-color-clematis-blue fs-14">
						<component :is="getLucideIcon('Folder')" :size="16" />
						{{ FormMSG(56, 'Group') }}
					</button>
				</b-col>
			</b-row>

			<!-- OPTIONS REPORTING -->
			<b-row>
				<b-col cols="12" style="margin-top: -8px">
					<div class="d-flex justify-content-end">
						<b-button variant="success" class="w-138-px mr-2" @click="handleClickReport(false)">
							<component v-if="!loadingForSubmit" :is="getLucideIcon('Save')" :size="16" />
							<b-spinner v-if="loadingForSubmit" small />
							{{ FormMSG(198, 'Save') }}
						</b-button>
						<b-button @click="handleClickReport(true)" variant="primary" :disabled="loadingForSubmit" class="w-138-px">
							<b-spinner v-if="loadingForSubmit" small />
							{{ FormMSG(68, 'Reports') }}
						</b-button>
					</div>
				</b-col>
			</b-row>

			<!-- CONFIGURATION REPORTING -->
			<div class="mt-3">
				<reporting-configuration :carbon-clap-data="carbonClapData" />
			</div>

			<!-- PREMISES REPORTING -->
			<div class="mt-3">
				<reporting-premises :premises-data="carbonClapData" :disable-fields="disableFields" @premises:error="handleComponentError" />
			</div>

			<!-- LOCATIONS REPORTING -->
			<div class="mt-3">
				<reporting-locations :locations-data="carbonClapData" :disable-fields="disableFields" @locations:error="handleComponentError" />
			</div>

			<!-- DECORATION REPORTING -->
			<div class="mt-3">
				<reporting-decoration
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-decoration:change="handleReportingDecorationChange"
				/>
			</div>

			<!-- COSTUMES, HAIR & MAKEUP REPORTING -->
			<div class="mt-3">
				<reporting-costumes
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-costumes:change="handleReportingCostumesChange"
				/>
			</div>

			<!-- TRANSPORTATION REPORTING -->
			<div class="mt-3">
				<reporting-transportation
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-transportation:change="handleReportingTransportationChange"
				/>
			</div>

			<!-- MANAGEMENT REPORTING -->
			<div class="mt-3">
				<reporting-management
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-management:change="handleReportingManagementChange"
				/>
			</div>

			<!-- TECHNICAL REPORTING -->
			<div class="mt-3">
				<reporting-technical
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-technical:change="handleReportingTechnicalChange"
				/>
			</div>

			<!-- POST PRODUCTION REPORTING -->
			<div class="mt-3">
				<reporting-post-production
					:disable-fields="disableFields"
					:carbon-clap-data="carbonClapData"
					@reporting-post-production:change="handleReportingPostProductionChange"
				/>
			</div>
		</b-card-text>
	</b-card>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { reportCarbonClap } from '@/cruds/carbon.crud';

import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import ReportingConfiguration from '@/modules/carbonclap/components/reporting/Configuration';
import ReportingPremises from '@/modules/carbonclap/components/reporting/Premises';
import ReportingLocations from '@/modules/carbonclap/components/reporting/Locations';
import ReportingDecoration from '@/modules/carbonclap/components/reporting/Decoration';
import ReportingCostumes from '@/modules/carbonclap/components/reporting/Costumes';
import ReportingTransportation from '@/modules/carbonclap/components/reporting/Transportation';
import ReportingManagement from '@/modules/carbonclap/components/reporting/Management';
import ReportingTechnical from '@/modules/carbonclap/components/reporting/Technical';
import ReportingPostProduction from '@/modules/carbonclap/components/reporting/PostProduction';

export default {
	name: 'ReportingForm',

	mixins: [languageMessages, globalMixin],

	props: {
		carbonClapData: { type: Object, default: () => ({}) },
		disableFields: { type: Boolean, default: false },
		finalReport: { type: Boolean, default: false, required: false },
		externalSubmit: { type: Boolean, default: false, required: false },
		isApi: { type: Boolean, default: false, required: false }
	},

	components: {
		CustomAccordion,
		ReportingConfiguration,
		ReportingPremises,
		ReportingLocations,
		ReportingDecoration,
		ReportingCostumes,
		ReportingTransportation,
		ReportingManagement,
		ReportingTechnical,
		ReportingPostProduction
	},

	data() {
		return {
			error: {},

			loading: false,
			dataToSend: {},
			loadingForSubmit: false
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.dataToSend = _.cloneDeep(newVal);
				}
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		emitChange() {
			this.$emit('reporting:change', this.dataToSend);
		},
		handleReportingTransportationChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingPostProductionChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingTechnicalChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingManagementChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingCostumesChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		handleReportingDecorationChange(payload) {
			this.dataToSend = {
				...this.dataToSend,
				...payload
			};

			this.emitChange();
		},
		async handleClickReport(isApi = false) {
			try {
				this.loadingForSubmit = true;
				await reportCarbonClap(this.finalReport, isApi, {
					..._.omit(this.dataToSend, ['id', '__typename', 'createdAt', 'updatedAt', 'projectId', 'uuid', 'apiKey', 'login', 'password'])
				});

				this.createToastForMobile(this.FormMSG(541, 'Success'), this.FormMSG(543, 'Updated data of carbon is successfully!'));
				this.loadingForSubmit = false;
			} catch (e) {
				this.loadingForSubmit = false;
				this.emitErrorEvent(e);
			}
		},
		emitErrorEvent(e) {
			this.$emit('reporting-form:error', e);
		},
		handleComponentError(payload) {
			this.error = payload;
		}
	}
};
</script>

<style scoped></style>
