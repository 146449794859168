<template>
	<custom-accordion :title="FormMSG(81, 'Configuration')" visible-initial>
		<template #content>
			<div>
				<b-row>
					<b-col cols="5">
						<b-form-group :label="FormMSG(71, 'Production name')">
							<b-form-input :value="carbonClapData.ccProjectName" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(72, 'Type')">
							<b-form-input :value="getLabelMenuProjectType(carbonClapData.productionType)" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="4">
						<b-form-group :label="FormMSG(720, 'Production')">
							<b-form-input :value="carbonClapData.production" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
						<b-form-group :label="FormMSG(75, 'Total duration of project')">
							<b-input-group>
								<b-form-input :value="carbonClapData.totalProduction" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(259, 'days') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="1">
						<b-form-group :label="FormMSG(101, 'No. of episodes')">
							<b-form-input :value="carbonClapData.numberOfEpisode" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(102, 'Film length/ avg episode length')">
							<b-input-group>
								<b-form-input :value="carbonClapData.filmLength" class="bg-color-grams-hair" disabled />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(104, 'min') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(103, 'Shooting format')">
							<b-form-select :options="optionForFormat" id="optionForFormat" v-model="carbonClapData.shootingFormat" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(73, 'Budget')">
							<b-input-group>
								<VueNumberFormat
									v-if="isEnglish"
									v-model="carbonClapData.budget"
									:options="{ precision: 2, prefix: '', isInteger: false, decimal: '.', thousand: ',' }"
									disabled
									class="form-control bg-color-grams-hair"
								></VueNumberFormat>
								<VueNumberFormat
									v-else
									v-model="carbonClapData.budget"
									:options="{ precision: 2, prefix: '', isInteger: false, decimal: ',', thousand: '.' }"
									disabled
									class="form-control bg-color-grams-hair"
								></VueNumberFormat>
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(74, 'Currency')">
							<b-form-input :value="projectCurrency" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="2">
						<b-form-group :label="FormMSG(105, 'Project start date')">
							<b-form-input :value="startDate" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(106, 'Project delivery date')">
							<b-form-input :value="endDate" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group :label="FormMSG(107, 'Majority producing country')">
							<b-form-input :value="carbonClapData.country" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col cols="5">
						<b-form-group :label="FormMSG(108, 'Country of co-production')">
							<v-select v-model="countriesProduction" multiple disabled class="bg-color-grams-hair" />
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import VueNumberFormat from '@igortrindade/vue-number-format';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'ReportingConfiguration',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion,
		VueNumberFormat
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}) }
	},

	computed: {
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		},
		isEnglish() {
			return store.appLanguage() === 0;
		},
		countriesProduction() {
			if (!_.isNil(this.carbonClapData.coProdCountries) && this.carbonClapData.coProdCountries !== '') {
				return this.carbonClapData.coProdCountries.split(',');
			}

			return '-';
		},
		project() {
			return store.state.myProject;
		},
		projectType() {
			return this.GetTextFromMenuNumberAndMenuValue(1025, store.state.myProject.projectType);
		},
		projectCurrency() {
			return this.GetTextFromMenuNumberAndMenuValue(5, store.state.myProject.currency);
		},
		differenceDate() {
			let date1 = new Date(store.state.myProject.startDate);
			let date2 = new Date(store.state.myProject.endDate);

			let differenceInTime = date2.getTime() - date1.getTime();

			let differenceInDays = differenceInTime / (1000 * 3600 * 24);

			return differenceInDays;
		},
		startDate() {
			return moment(store.state.myProject.startDate).format('DD/MM/YYYY');
		},
		endDate() {
			return moment(store.state.myProject.endDate).format('DD/MM/YYYY');
		},
		optionForFormat() {
			return this.FormMenu(1355);
		}
	},
	methods: {
		getLabelMenuProjectType(value) {
			const menues = [...this.FormMenu(1350), ...this.FormMenu(1351), ...this.FormMenu(1352), ...this.FormMenu(1353), ...this.FormMenu(1354)];

			const indexMenu = menues.findIndex((option) => option.value === value);

			if (indexMenu > -1) {
				return menues[indexMenu].text;
			}

			return '';
		}
	}
};
</script>

<style scoped></style>
