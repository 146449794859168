<template>
	<custom-accordion
		:title="FormMSG(125, 'Premises')"
		use-external-value
		:external-value="toggleCollapse"
		:is-loading="loading"
		@custom-accordion:click="handleCustomAccordionClick"
	>
		<template #content>
			<div>
				<div v-for="(premise, index) in premises" :key="index">
					<div v-if="index === 0" class="d-flex mb-2">
						<div class="text-color-blue-streak pt-1">
							<component :is="getLucideIcon('Info')" :size="16" />
						</div>
						<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14">
							{{
								FormMSG(
									126,
									'Premises are rented office and studio spaces that are not related to individual filming locations.  Premises shown have been pulled from the main project setup page and their main details cannot be edited on this screen.  If you need to amend main details or add a premises go to Configuration > Project, Premises tab, if you do not have access, consult the Production department.'
								)
							}}
						</div>
					</div>
					<div class="ml-4 w-85 mt-4">
						<b-card no-body class="pb-5">
							<b-row class="mb-3">
								<b-col>
									<div class="fs-18 fw-400 text-color-rhapsody-in-blue">{{ FormMSG(254, 'Premises') }} {{ index + 1 }}</div>
								</b-col>
							</b-row>
							<div class="ml-5">
								<b-row>
									<b-col cols="2">
										<b-form-group :label="FormMSG(214, 'Name')">
											<b-form-input :value="premise.setName" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(215, 'Type of premises')">
											<b-form-input
												:value="getTypePremise(premise.type)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(216, 'Country')">
											<b-form-input
												:value="premise.address5.countryCode"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="2">
										<b-form-group :label="FormMSG(217, 'Area of premises')">
											<b-input-group>
												<b-form-input
													:value="premise.filmingArea"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(224, 'm2') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(218, 'Total occupation')">
											<b-input-group>
												<b-form-input
													:value="premise.totNbOfDaysOccupation"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(259, 'days') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(219, 'Energy consumption')">
											<b-input-group>
												<b-form-input
													:value="sumEnergy(premise.carbon.elecMeterEnd, premise.carbon.elecMeterStart)"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(225, 'kWh') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(220, 'Total no. of man days')">
											<b-input-group>
												<b-form-input
													:value="premise.totNbOfManDays"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(259, 'days') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
									<b-col cols="1" class="pr-0">
										<b-form-group :label="FormMSG(221, 'Air conditioners')">
											<b-form-input
												:value="premise.nbOfAirConditioners"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="2">
										<b-form-group :label="FormMSG(222, 'Energy sobriety plan')">
											<b-form-input
												:value="getEnergySavingPlanLabel(premise.carbon.type)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(223, 'Green energy supplier')">
											<b-form-input
												:value="getYesOrNoLabel(premise.carbon.greenEnergySupplier)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="1" class="pr-0">
										<b-form-group :label="FormMSG(229, 'Sorted waste')">
											<b-form-input
												:value="getYesOrNoLabel(premise.carbon.sortedWaste)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group :label="FormMSG(228, 'Uses recycled paper')">
											<b-form-input
												:value="getYesOrNoLabel(premise.carbon.recycledPaper)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="2" class="pr-0">
										<b-form-group :label="FormMSG(226, 'Paper consumption')">
											<b-input-group>
												<b-form-input
													:value="premise.carbon.paperPrinted"
													:class="{ 'bg-color-grams-hair': isDisable }"
													:disabled="isDisable"
												/>
												<b-input-group-append>
													<b-input-group-text class="bg-color-north-wind text-color-white">
														{{ FormMSG(227, 'sheets') }}
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="mt-4">
									<b-col cols="4">
										<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
											<legend
												:class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`"
												class="text-color-rhapsody-in-blue-2 fw-400"
											>
												{{ FormMSG(625, 'Equipment') }}
											</legend>
											<div v-if="premise.computerEquipments.length > 0" class="pt-3 pb-3">
												<b-row v-for="(equipment, index2) in premise.computerEquipments" :key="index + '' + index2">
													<b-col cols="6">
														<b-form-group :label="index2 > 0 ? '' : FormMSG(230, 'Type')">
															<b-input-group>
																<b-form-input
																	:value="getEquipmentType(equipment.type)"
																	:class="{ 'bg-color-grams-hair': isDisable }"
																	:disabled="isDisable"
																/>
																<b-input-group-append>
																	<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
																		<button class="btn-transparent text-color-rhapsody-in-blue">
																			<component :is="getLucideIcon('ExternalLink')" :size="16" />
																		</button>
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</b-form-group>
													</b-col>
													<b-col cols="3" class="pl-0">
														<b-form-group :label="index2 > 0 ? '' : FormMSG(264, 'Quantity')">
															<b-form-input
																v-model="equipment.quantity"
																:class="{ 'bg-color-grams-hair': isDisable }"
																:disabled="isDisable"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</div>
											<div v-else class="pt-3 pb-3">
												<div class="text-center">
													{{ FormMSG(511, 'No equipment') }}
												</div>
											</div>
										</fieldset>
									</b-col>
								</b-row>
							</div>
						</b-card>
					</div>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getProjectLocationListForPremises } from '@/cruds/premises.crud';

export default {
	name: 'ReportingPremises',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion
	},

	props: {
		disableFields: { type: Boolean, default: false },
		premisesData: {
			type: Object,
			default: () => ({})
		}
	},

	data() {
		return {
			premises: [],
			toggleCollapse: false,
			loading: false
		};
	},
	// watch: {
	// 	premisesData: {
	// 		handler(val) {
	// 			this.premises = val.premises;
	// 		},
	// 		immediate: true,
	// 		deep: true
	// 	}
	// },

	computed: {
		isDisable() {
			return this.disableFields;
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		}
	},

	methods: {
		sumEnergy(val1, val2) {
			return val1 - val2;
		},
		getYesOrNoLabel(value) {
			if (value === false) return this.FormMSG(15, 'No');
			if (value === true) return this.FormMSG(14, 'Yes');
		},
		getEnergySavingPlanLabel(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1359, value);
		},
		getEquipmentType(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1401, value);
		},
		getTypePremise(value) {
			return this.GetTextFromMenuNumberAndMenuValue(1400, value);
		},
		async handleCustomAccordionClick(payload) {
			// if (payload === false) {
			// 	if (this.premises.length === 0) {
			// 		this.loading = true;
			// 		await this.getPremises()
			// 			.then(() => {
			// 				this.loading = false;
			// 				this.toggleCollapse = true;
			// 			})
			// 			.catch((error) => {
			// 				this.$emit('premises:error', error);
			// 			});
			// 	} else {
			// 		this.toggleCollapse = true;
			// 	}
			// } else {
			// 	this.toggleCollapse = !payload;
			// }
			this.loading = true;
			this.premises = this.premisesData.premises;
			console.log(this.premises);
			this.loading = false;
			this.toggleCollapse = !payload;
		},
		async getPremises() {
			await getProjectLocationListForPremises()
				.then((result) => {
					this.premises = result;
				})
				.catch((error) => {
					this.$emit('premises:error', error);
				});
		}
	}
};
</script>

<style scoped></style>
