<template>
	<custom-accordion :title="FormMSG(301, 'Costumes, Hair & Makeup')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								302,
								'Figures shown have been pulled from the General Ledger screen and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.'
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(272, 'Cost') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(303, 'Costume purchases')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="costumes.costumeBuyTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(304, 'Cost of making costumes')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="costumes.costumeMakingTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(305, 'Costume rentals')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="costumes.costumeRentalTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(306, 'over 50% donated')">
										<b-form-select v-model="costumes.costumeReuse" :options="yesNoOptions" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(307, 'Makeup purchases')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="costumes.makeupTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(308, 'Hairdressing purchases')">
										<b-input-group>
											<b-input-group-prepend>
												<b-input-group-text
													class="input-prepend"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
												</b-input-group-text>
											</b-input-group-prepend>
											<b-form-input
												v-model="costumes.hairCostTotal"
												class="br-none bl-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text
													class="input-group-text-bg-grams-hair"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import CurrencySvg from '@/components/Svg/Currency';
import globalMixin from '@/mixins/global.mixin';

import _ from 'lodash';

export default {
	name: 'ReportingCostumes',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion,
		CurrencySvg
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			costumes: {
				costumeBuyTotal: 0,
				costumeMakingTotal: 0,
				costumeRentalTotal: 0,
				costumeReuse: 0,
				makeupTotal: 0,
				hairCostTotal: 0,
				costumeReuse: false
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.costumes.costumeBuyTotal = newVal.costumeBuyTotal;
					this.costumes.costumeMakingTotal = newVal.costumeMakingTotal;
					this.costumes.costumeRentalTotal = newVal.costumeRentalTotal;
					this.costumes.costumeReuse = newVal.costumeReuse;
					this.costumes.makeupTotal = newVal.makeupTotal;
					this.costumes.hairCostTotal = newVal.hairCostTotal;
				}
			},
			immediate: true,
			deep: true
		},
		costumes: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		yesNoOptions() {
			return [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
		}
	},

	methods: {
		emitChange(newVal) {
			this.costumes.costumeBuyTotal = parseFloat(newVal.costumeBuyTotal);
			this.costumes.costumeMakingTotal = parseFloat(newVal.costumeMakingTotal);
			this.costumes.costumeRentalTotal = parseFloat(newVal.costumeRentalTotal);
			this.costumes.costumeReuse = newVal.costumeReuse;
			this.costumes.makeupTotal = parseFloat(newVal.makeupTotal);
			this.costumes.hairCostTotal = parseFloat(newVal.hairCostTotal);

			this.$emit('reporting-costumes:change', this.costumes);
		}
	}
};
</script>

<style scoped></style>
