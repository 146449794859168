var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c("div", { attrs: { id: "greenReportingScreen" } }, [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-row",
                { staticClass: "tab-carbon-clap" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "pos-relative",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "b-tabs",
                            {
                              staticClass: "px-4",
                              attrs: {
                                cards: "",
                                "active-nav-item-class": "font-weight-bold",
                                "active-tab-class": "font-weight-bold",
                              },
                              on: { input: _vm.handleInputTabs },
                              model: {
                                value: _vm.tabActive,
                                callback: function ($$v) {
                                  _vm.tabActive = $$v
                                },
                                expression: "tabActive",
                              },
                            },
                            [
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _vm.loadingTitleTableForecast
                                                ? _c("b-spinner", {
                                                    attrs: { small: "" },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(487, "Forecast")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { staticClass: "p-0" },
                                        [
                                          _c("reporting-form", {
                                            attrs: {
                                              "carbon-clap-data":
                                                _vm.carbonClapDataForecast,
                                              "final-report": false,
                                              "is-api": true,
                                              "external-submit":
                                                _vm.submitForecast,
                                            },
                                            on: {
                                              "reporting:change":
                                                _vm.handleReportingForecastChange,
                                              "reporting-form:error":
                                                _vm.handleReportingFormError,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u([
                                    {
                                      key: "title",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _vm.loadingTitleTableFinalReport
                                                ? _c("b-spinner", {
                                                    attrs: { small: "" },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      488,
                                                      "Final Report"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { staticClass: "p-0" },
                                        [
                                          _c("reporting-form", {
                                            attrs: {
                                              "carbon-clap-data":
                                                _vm.carbonClapDataFinalReport,
                                              "disable-fields": "",
                                              "final-report": true,
                                              "is-api": true,
                                              "external-submit":
                                                _vm.submitFinalReport,
                                            },
                                            on: {
                                              "reporting:change":
                                                _vm.handleReportingFinalReportChange,
                                              "reporting-form:error":
                                                _vm.handleReportingFormError,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    "header-class": "header-class-modal-doc-package",
                    "header-bg-variant": "warning",
                    "header-border-variant": "warning",
                    "ok-title": _vm.FormMSG(578, "Go to project config"),
                    "hide-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    "ok-variant": "primary",
                    "ok-only": "",
                  },
                  on: { ok: _vm.handleOk },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-title",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            [
                              _c(_vm.getLucideIcon("AlertTriangle"), {
                                tag: "component",
                                attrs: { size: 20 },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      70,
                                      "Carbon clap login not found"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.showModalWarning,
                    callback: function ($$v) {
                      _vm.showModalWarning = $$v
                    },
                    expression: "showModalWarning",
                  },
                },
                [
                  _c("div", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            76,
                            "You don't have access, you have to enter the login information for the project configuration"
                          )
                        ) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }