<template>
	<custom-accordion :title="FormMSG(350, 'Technical')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								351,
								'Figures shown have been pulled from the Waste, Rentals and Transportation screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste and Transportation figures can be viewed and amended in the Green > Waste or Transportation windows. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(352, 'Waste') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<b-form-group :label="FormMSG(353, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(560, 'Batteries disposable')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(354, 'Total')">
										<b-form-input
											v-model="technical.nbrBatteriesThrown"
											:class="{ 'bg-color-grams-hair': isDisable }"
											:disabled="isDisable"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(355, 'Costs') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<b-form-group :label="FormMSG(356, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(625, 'Technical rentals')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(357, 'Amount')">
										<b-input-group>
											<b-form-input
												v-model="technical.amountCameraRental"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(626, 'Lighting rentals')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="technical.amountLightingRental"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(627, 'Machinery rentals')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="technical.amountMachineryRental"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(628, 'Sound rentals')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="technical.amountSoundMaterialRental"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(629, 'Films and carriers')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="technical.amountOfFilmsSupport"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(630, 'Special effects')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="technical.amountOfSpecialEffect"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(358, 'Transportation') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="3">
									<b-form-group :label="FormMSG(359, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(631, 'Canteens and coaches')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(360, 'Distance')">
										<b-input-group>
											<b-form-input
												v-model="technical.sumCarsLogesKm"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ distanceUnit }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(361, 'Average size')">
										<b-form-input v-model="technical.sizeOfCarLoge" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(362, 'Canteen generator')">
										<b-form-input
											v-model="technical.energyTypeOfCarLoge"
											:class="{ 'bg-color-grams-hair': isDisable }"
											:disabled="isDisable"
										/>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(363, 'Generator fuel used')">
										<b-input-group>
											<b-form-input
												v-model="technical.volumeEnergyCarLoge"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> L </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(680, 'Car diesel')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="technical.carDiesel" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">{{ distanceUnit }}</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(691, 'Car electric')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="technical.carElectric" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">{{ distanceUnit }}</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(69154, 'Car gasoline')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="technical.carGasoline" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">{{ distanceUnit }}</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(69156, 'Moto > 250cc')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="technical.motoLarge" :class="{ 'bg-color-grams-hair': isDisable }" :disabled="isDisable" />
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">{{ distanceUnit }}</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group :label="FormMSG(359, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(69132, 'Ultralight aircraft class 2')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(35999, 'Flight duration')">
										<b-input-group>
											<b-form-input
												:value="convertMinutesToHours(technical.ulmPlaneClassTwo)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"><Clock :size="15" /> </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(69141, 'Ultralight aircraft class 3')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="convertMinutesToHours(technical.ulmPlaneClassThree)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"><Clock :size="15" /></b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="3">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(69681, 'Helicopter')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="convertMinutesToHours(technical.helicopter)"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"><Clock :size="15" /></b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';
import { Clock } from 'lucide-vue';

export default {
	name: 'ReportingTechnical',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion,
		Clock
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			technical: {
				nbrBatteriesThrown: 0,
				amountCameraRental: 0,
				amountLightingRental: 0,
				amountMachineryRental: 0,
				amountSoundMaterialRental: 0,
				amountOfFilmsSupport: 0,
				amountOfSpecialEffect: 0,
				sumCarsLogesKm: 0,
				sizeOfCarLoge: '',
				energyTypeOfCarLoge: '',
				volumeEnergyCarLoge: 0,
				carDiesel: 0,
				carElectric: 0,
				carGasoline: 0,
				ulmPlaneClassTwo: '00:00',
				motoLarge: 0,
				helicopter: '00:00',
				ulmPlaneClassThree: '00:00'
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal)) {
					this.technical.nbrBatteriesThrown = newVal.nbrBatteriesThrown;
					this.technical.amountCameraRental = newVal.amountCameraRental;
					this.technical.amountLightingRental = newVal.amountLightingRental;
					this.technical.amountMachineryRental = newVal.amountMachineryRental;
					this.technical.amountSoundMaterialRental = newVal.amountSoundMaterialRental;
					this.technical.amountOfFilmsSupport = newVal.amountOfFilmsSupport;
					this.technical.amountOfSpecialEffect = newVal.amountOfSpecialEffect;
					this.technical.sumCarsLogesKm = newVal.sumCarsLogesKm;
					this.technical.sizeOfCarLoge = newVal.sizeOfCarLoge;
					this.technical.energyTypeOfCarLoge = newVal.energyTypeOfCarLoge;
					this.technical.volumeEnergyCarLoge = newVal.volumeEnergyCarLoge;
					this.technical.carDiesel = newVal.carDiesel;
					this.technical.carElectric = newVal.carElectric;
					this.technical.carGasoline = newVal.carGasoline;
					this.technical.ulmPlaneClassTwo = newVal.ulmPlaneClassTwo;
					this.technical.motoLarge = newVal.motoLarge;
					this.technical.helicopter = newVal.helicopter;
					this.technical.ulmPlaneClassThree = newVal.ulmPlaneClassThree;
				}
			},
			immediate: true,
			deep: true
		},
		technical: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isDisable() {
			return this.disableFields;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		},
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		}
	},
	methods: {
		convertMinutesToHours(value) {
			if (value <= 0) {
				return '00:00';
			} else {
				let min = value;
				let hours = min / 60;
				let rhours = Math.floor(hours);
				let minutes = (hours - rhours) * 60;
				let rminutes = Math.round(minutes);

				let result = `${rhours < 10 ? '0' + rhours : rhours}:${rminutes < 10 ? '0' + rminutes : rminutes}`;

				return result === '00:00' ? '00:00' : result;
			}
		},
		emitChange(newVal) {
			this.technical.nbrBatteriesThrown = +newVal.nbrBatteriesThrown;
			this.technical.amountCameraRental = parseFloat(newVal.amountCameraRental);
			this.technical.amountLightingRental = parseFloat(newVal.amountLightingRental);
			this.technical.amountMachineryRental = parseFloat(newVal.amountMachineryRental);
			this.technical.amountSoundMaterialRental = parseFloat(newVal.amountSoundMaterialRental);
			this.technical.amountOfFilmsSupport = parseFloat(newVal.amountOfFilmsSupport);
			this.technical.amountOfSpecialEffect = parseFloat(newVal.amountOfSpecialEffect);
			this.technical.sumCarsLogesKm = parseFloat(newVal.sumCarsLogesKm);
			this.technical.sizeOfCarLoge = newVal.sizeOfCarLoge;
			this.technical.energyTypeOfCarLoge = newVal.energyTypeOfCarLoge;
			this.technical.volumeEnergyCarLoge = parseFloat(newVal.volumeEnergyCarLoge);

			this.$emit('reporting-technical:change', this.technical);
		}
	}
};
</script>

<style scoped></style>
