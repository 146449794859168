<template>
	<custom-accordion :title="FormMSG(330, 'Management')">
		<template #content>
			<div>
				<div class="d-flex mb-2">
					<div class="text-color-blue-streak pt-1">
						<component :is="getLucideIcon('Info')" :size="16" />
					</div>
					<div class="ml-2 w-90 text-color-rhapsody-in-blue-2 fs-14 fw-400">
						{{
							FormMSG(
								331,
								'Figures shown have been pulled from the Waste, Accommodations and Menus screens and their details cannot be edited on this screen.  You can click on the expand icons to view reports with individual entries.  If you need to amend cost entries consult the accounts department.  Waste figures can be viewed and amended in the Green > Waste window. If you do not have access, speak with the Production department.  '
							)
						}}
					</div>
				</div>
				<div class="ml-4 mt-4 w-90">
					<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(332, 'Spending') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<b-form-group :label="FormMSG(333, 'Total spend on small consumables & misc')">
										<b-input-group>
											<VueNumberFormat
												v-if="isEnglish"
												v-model="management.setSmallConsumableTotal"
												:options="{ precision: 2, prefix: '', isInteger: false, decimal: '.', thousand: ',' }"
												:disabled="isDisable"
												class="form-control bg-color-grams-hair"
											></VueNumberFormat>
											<VueNumberFormat
												v-else
												v-model="management.setSmallConsumableTotal"
												:options="{ precision: 2, prefix: '', isInteger: false, decimal: ',', thousand: '.' }"
												:disabled="isDisable"
												class="form-control bg-color-grams-hair"
											></VueNumberFormat>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white"> {{ currencySymbol }} </b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(334, 'Waste') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="2">
									<b-form-group :label="FormMSG(335, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(264, 'Cardboard')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(336, 'Measurement')">
										<b-input-group>
											<b-form-input
												:value="number_format(management.setCardboardWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(365, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<!-- <b-col cols="2" class="pt-17">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(492, 'Paint')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2" class="pt-17">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="number_format(management.setPaintLiterWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(493, 'L') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col> -->
								<b-col cols="2" class="pt-17">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(495, 'Steel')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2" class="pt-17">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="number_format(management.setSteelWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(365, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(497, 'Wood')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="number_format(management.setWoodWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(365, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(496, 'Mixed')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="number_format(management.setMixedWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(365, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(270, 'Plastic')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												:value="number_format(management.setPlasticWasteTotal, 0, '.', ',')"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(365, 'kg') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(337, 'Accomodations') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<b-form-group :label="FormMSG(338, 'Total')">
										<b-input-group>
											<b-form-input
												v-model="management.nbOvernight"
												class="br-none"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<button
													class="btn-transparent text-color-rhapsody-in-blue bt-light-starlight bb-light-starlight pr-3"
													:class="{ 'bg-color-grams-hair': isDisable, 'bg-color-white': !isDisable }"
												>
													<component :is="getLucideIcon('ExternalLink')" :size="16" />
												</button>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(339, 'nights') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>

					<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
						<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
							{{ FormMSG(340, 'Meals') }}
						</legend>
						<div class="pt-3 pb-4">
							<b-row>
								<b-col cols="4">
									<b-form-group :label="FormMSG(335, 'Type')">
										<b-input-group>
											<b-form-input :value="FormMSG(489, 'Red meat')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group :label="FormMSG(341, 'Total')">
										<b-input-group>
											<b-form-input
												v-model="management.nbRedMeatCatering"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(342, 'meals') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(490, 'Fish/ white meat')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="management.nbWhiteMeatCatering"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(342, 'meals') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(491, 'Vegetarian/ vegan')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="management.nbVegetarianCatering"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(342, 'meals') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="4">
									<b-form-group>
										<b-input-group>
											<b-form-input :value="FormMSG(498, 'Not specified')" class="bg-color-grams-hair br-none" disabled />
											<b-input-group-append>
												<b-input-group-text class="bg-color-grams-hair input-group-text-bg-grams-hair">
													<button class="btn-transparent text-color-rhapsody-in-blue">
														<component :is="getLucideIcon('ExternalLink')" :size="16" />
													</button>
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group>
										<b-input-group>
											<b-form-input
												v-model="management.nbNotSpecifiedCatering"
												:class="{ 'bg-color-grams-hair': isDisable }"
												:disabled="isDisable"
											/>
											<b-input-group-append>
												<b-input-group-text class="bg-color-north-wind text-color-white">
													{{ FormMSG(342, 'meals') }}
												</b-input-group-text>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</custom-accordion>
</template>

<script>
import CustomAccordion from '@/modules/carbonclap/components/CustomAccordion';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import VueNumberFormat from '@igortrindade/vue-number-format';
import _ from 'lodash';

export default {
	name: 'ReportingManagement',

	mixins: [languageMessages, globalMixin],

	components: {
		CustomAccordion,
		VueNumberFormat
	},

	props: {
		carbonClapData: { type: Object, default: () => ({}), required: true },
		disableFields: { type: Boolean, default: false }
	},

	data() {
		return {
			management: {
				setSmallConsumableTotal: 0,
				setMaterialSorted: false,
				setPaintLiterWasteTotal: 0,
				setCardboardWasteTotal: 0,
				setWoodWasteTotal: 0,
				setSteelWasteTotal: 0,
				setPlasticWasteTotal: 0,
				setMixedWasteTotal: 0,
				nbOvernight: 0,
				nbRedMeatCatering: 0,
				nbWhiteMeatCatering: 0,
				nbVegetarianCatering: 0,
				nbNotSpecifiedCatering: 0
			}
		};
	},

	watch: {
		carbonClapData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.management.setSmallConsumableTotal = newVal.setSmallConsumableTotal;
					this.management.setMaterialSorted = newVal.setMaterialSorted;
					this.management.setPaintLiterWasteTotal = newVal.setPaintLiterWasteTotal;
					this.management.setCardboardWasteTotal = newVal.setCardboardWasteTotal;
					this.management.setWoodWasteTotal = newVal.setWoodWasteTotal;
					this.management.setSteelWasteTotal = newVal.setSteelWasteTotal;
					this.management.setPlasticWasteTotal = newVal.setPlasticWasteTotal;
					this.management.setMixedWasteTotal = newVal.setMixedWasteTotal;
					this.management.nbOvernight = newVal.nbOvernight;
					this.management.nbRedMeatCatering = newVal.nbRedMeatCatering;
					this.management.nbWhiteMeatCatering = newVal.nbWhiteMeatCatering;
					this.management.nbVegetarianCatering = newVal.nbVegetarianCatering;
					this.management.nbNotSpecifiedCatering = newVal.nbNotSpecifiedCatering;
				}
			},
			immediate: true,
			deep: true
		},
		management: {
			handler(newVal) {
				this.emitChange(newVal);
			},
			deep: true
		}
	},

	computed: {
		isEnglish() {
			return store.appLanguage() == 0;
		},
		isDisable() {
			return this.disableFields;
		},
		currencySymbol() {
			return store.state.myProfile.currencySymbol;
		}
	},

	methods: {
		emitChange(newVal) {
			this.management.setSmallConsumableTotal = parseFloat(newVal.setSmallConsumableTotal);
			this.management.setMaterialSorted = newVal.setMaterialSorted;
			this.management.setPaintLiterWasteTotal = parseFloat(newVal.setPaintLiterWasteTotal);
			this.management.setCardboardWasteTotal = parseFloat(newVal.setCardboardWasteTotal);
			this.management.setWoodWasteTotal = parseFloat(newVal.setWoodWasteTotal);
			this.management.setSteelWasteTotal = parseFloat(newVal.setSteelWasteTotal);
			this.management.setPlasticWasteTotal = parseFloat(newVal.setPlasticWasteTotal);
			this.management.setMixedWasteTotal = parseFloat(newVal.setMixedWasteTotal);
			this.management.nbOvernight = newVal.nbOvernight;
			this.management.nbRedMeatCatering = newVal.nbRedMeatCatering;
			this.management.nbWhiteMeatCatering = newVal.nbWhiteMeatCatering;
			this.management.nbVegetarianCatering = newVal.nbVegetarianCatering;
			this.management.nbNotSpecifiedCatering = newVal.nbNotSpecifiedCatering;

			this.$emit('reporting-management:change', this.management);
		}
	}
};
</script>

<style scoped></style>
