var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "pt-0 border-none", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-text",
        { staticClass: "pb-3" },
        [
          false
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-clematis-blue fs-14 mr-3",
                        attrs: { type: "button" },
                      },
                      [
                        _c(_vm.getLucideIcon("Filter"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(54, "Filter")) +
                            "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-clematis-blue fs-14 mr-3",
                        attrs: { type: "button" },
                      },
                      [
                        _c(_vm.getLucideIcon("ArrowUpDown"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(55, "Sort")) +
                            "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-clematis-blue fs-14",
                        attrs: { type: "button" },
                      },
                      [
                        _c(_vm.getLucideIcon("Folder"), {
                          tag: "component",
                          attrs: { size: 16 },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(56, "Group")) +
                            "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticStyle: { "margin-top": "-8px" },
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "w-138-px mr-2",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickReport(false)
                            },
                          },
                        },
                        [
                          !_vm.loadingForSubmit
                            ? _c(_vm.getLucideIcon("Save"), {
                                tag: "component",
                                attrs: { size: 16 },
                              })
                            : _vm._e(),
                          _vm.loadingForSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(198, "Save")) +
                              "\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "w-138-px",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.loadingForSubmit,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleClickReport(true)
                            },
                          },
                        },
                        [
                          _vm.loadingForSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(68, "Reports")) +
                              "\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-configuration", {
                attrs: { "carbon-clap-data": _vm.carbonClapData },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-premises", {
                attrs: {
                  "premises-data": _vm.carbonClapData,
                  "disable-fields": _vm.disableFields,
                },
                on: { "premises:error": _vm.handleComponentError },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-locations", {
                attrs: {
                  "locations-data": _vm.carbonClapData,
                  "disable-fields": _vm.disableFields,
                },
                on: { "locations:error": _vm.handleComponentError },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-decoration", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-decoration:change":
                    _vm.handleReportingDecorationChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-costumes", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-costumes:change":
                    _vm.handleReportingCostumesChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-transportation", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-transportation:change":
                    _vm.handleReportingTransportationChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-management", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-management:change":
                    _vm.handleReportingManagementChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-technical", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-technical:change":
                    _vm.handleReportingTechnicalChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("reporting-post-production", {
                attrs: {
                  "disable-fields": _vm.disableFields,
                  "carbon-clap-data": _vm.carbonClapData,
                },
                on: {
                  "reporting-post-production:change":
                    _vm.handleReportingPostProductionChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }